import { FileFoldersList } from './fileFolderList.js';

/**
 * Update the now playing label for a track
 *
 * @param trackTitle
 */
const updateNowPlayingTrack = (trackTitle) => {

    let titleParts = trackTitle.split('/');
    const fileName = titleParts.pop();
    titleParts.shift();
    const folderName = titleParts.pop();

    console.log('Now playing - ' + fileName + ' - ' + folderName);
    const trackLabel = document.querySelector('#song-title');
    if(trackLabel){
        trackLabel.textContent = fileName + ' - ' + folderName;
    }
}

const playTrack = (folder, songIndex) => {

    const player = document.querySelector('#audio-player');

    let queryString = '?folder=' + encodeURIComponent(folder) + '&songIndex=' + songIndex;

    player.pause();

    player.dataset.albumCurrent = folder;

    player.src = window.playFilePath + queryString;

    player.play();

    FileFoldersList.getFile(folder, parseInt(songIndex)).then((data) => {
        if(data){
            updateNowPlayingTrack(data);
        }
    });
};

/**
 * Initialize the Audio Player
 *
 */
const initAudioPlayer = () => {

    window.songIndex = 0;

    const player = document.querySelector('#audio-player');

    player.onended = () => {

        console.log('Song ended.');

        window.songIndex++;

        let queryString = '?folder=' + encodeURIComponent(player.dataset.albumCurrent) + '&songIndex=' + songIndex;

        player.src = window.playFilePath + queryString;

        console.log('Now playing - ' + player.dataset.albumCurrent + ' - ' + (songIndex + 1));

        player.play();

    }
};

export { initAudioPlayer, playTrack };


