import { playTrack } from './audioplayer.js';

/**
 * FilesFoldersList
 * Manages the logic and listeners for the
 * sidebar where tracks and folders from the server
 * source mp3 directory are displayed.
 */
class FileFoldersList {

    /**
     * Render the list of mp3 folders in the sidebar
     * Attach event listeners
     *
     * @return void
     */
    static async renderFoldersList(){
        const foldersEl = document.querySelector('#folder-list');
        if(foldersEl){
            const folders = await FileFoldersList.getFolders();
            if(folders){

                folders.forEach((folder) => {
                    let listItem = document.createElement('li');
                    listItem.innerHTML = '> ' + folder;
                    listItem.dataset.folder = folder;
                    listItem.classList.add('folder');
                    foldersEl.appendChild(listItem);
                    FileFoldersList.attachTrackListEventListener(listItem);
                });

                FileFoldersList.attachFoldersListEventListeners();
            }
        }
    }

    /**
     * Get a list of folders from the mp3 destination on the server
     * Call the API endpoint to return
     *
     * @returns {Promise.<T>}
     */
    static getFolders() {
        return fetch('/api/v1/get-folders.php')
            .then(response => response.json())
            .then(data => data);
    }

    /**
     *
     * Get a list of files from the API endpoint
     * Based on a folder with music files in them
     *
     * @param folder
     * @returns {Promise.<T>}
     */
    static async getFiles(folder = null){

        let queryString = new URLSearchParams();

        if(folder){
            queryString.set('folder', encodeURIComponent(folder));
        }

        return fetch('./api/v1/get-files.php?' + queryString.toString())
            .then(response => response.json())
            .then(data => data);
    }

    /**
     * Set up folder sidebar "click to play" event listener
     *
     * @return void
     */
    static attachFoldersListEventListeners(){

        const albums = document.querySelectorAll('.folder');
        const player = document.querySelector('#audio-player');

        if(albums){
            albums.forEach((album) => {

                album.addEventListener('click', (e) => {

                    // reset the song index to start
                    window.songIndex = 0;

                    // only play full folders if the folder title is clicked
                    if(!e.target.classList.contains('folder')){
                        return;
                    }

                    let folder = e.target.dataset.folder;

                    playTrack(folder, window.songIndex);
                });
            });
        }
    }

    /**
     * Set up individual track sidebar item "click to play" listener
     *
     * @param item
     * @return void;
     */
    static attachTrackListEventListener(item){

        let tracksEl = document.createElement('ul');
        tracksEl.classList.add('track-list');
        const clickLabel = 'Click to view tracks';
        tracksEl.innerHTML = clickLabel;
        item.appendChild(tracksEl);

        tracksEl.dataset.folder = item.dataset.folder;

        tracksEl.addEventListener('click', (e) => {

            // do not re-render the track list if a track list item is clicked
            if(e.target.classList.contains('track')){
                return;
            }

            let getTracks = FileFoldersList.getFiles(e.target.dataset.folder);
            getTracks.then((data) => {
                FileFoldersList.renderTrackList(tracksEl, data);
            });

            if(e.target.textContent == clickLabel){
                e.target.textContent = '';
            }
        });
    }

    /**
     * Render a list of tracks in a folder in the sidebar
     *
     * @param trackListParentEl
     * @param tracklistData
     * @return void
     *
     */
    static renderTrackList(trackListParentEl = null, tracklistData = null){
        console.log('Rendering track lists');
        let songIndex = 0;
        if(tracklistData && trackListParentEl){
            tracklistData.forEach((track) => {
                let trackListItem = document.createElement('li');
                trackListItem.textContent = '> ' + track;
                trackListItem.classList.add('track');
                trackListItem.dataset.trackName = track;
                trackListItem.dataset.folder = trackListParentEl.dataset.folder;
                trackListItem.dataset.songIndex = songIndex;
                trackListItem.addEventListener('click', (e) => {
                    playTrack(e.target.dataset.folder, e.target.dataset.songIndex);
                });
                trackListParentEl.appendChild(trackListItem);
                songIndex++;
            });
        }
    }

    /**
     * Get a single file for metadata purposes (not to play)
     *
     * @param folder
     * @param index
     * @returns {Promise.<T>}
     */
    static async getFile(folder, index){

        let queryString = new URLSearchParams();

        queryString.set('folder', encodeURIComponent(folder));
        queryString.set('songIndex', encodeURIComponent(index));

        return fetch('./api/v1/get-file.php?' + queryString.toString())
            .then(response => response.json())
            .then(data => data);
    }
}

export { FileFoldersList };