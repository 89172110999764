import './../scss/main.scss'; // main styles
import { initAudioPlayer } from './audioplayer.js';
import { FileFoldersList } from './fileFolderList.js';

/**
 * Initialize the JS app
 */
window.addEventListener('load', () => {

    // set up endpoints
    window.playFilePath = './api/v1/play-file.php';

    initAudioPlayer();
    FileFoldersList.renderFoldersList();
    console.log('Crapify Initialized');
});